import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Anonymity`}</h1>
    <ul>
      <li parentName="ul">{`Polis is, by default, anonymous to vote and requires users to log in to submit a `}<a parentName="li" {...{
          "href": "/comment",
          "title": "comment"
        }}>{`comment`}</a>{` (adding slight friction for spamming text fields)`}</li>
      <li parentName="ul">{`Polis sets a cookie on each `}<a parentName="li" {...{
          "href": "/participant",
          "title": "participant"
        }}>{`participant`}</a>{`'s browser so that if they return with the same browser to vote anonymously, they are the same user. If they return from a different device, they are a new and different user.`}</li>
      <li parentName="ul">{`A `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{` `}<a parentName="li" {...{
          "href": "/owner",
          "title": "owner"
        }}>{`owner`}</a>{` can force users to log in for `}<a parentName="li" {...{
          "href": "/commenting",
          "title": "commenting"
        }}>{`commenting`}</a>{` and / or `}<a parentName="li" {...{
          "href": "/voting",
          "title": "voting"
        }}>{`voting`}</a>{` by changing the `}<a parentName="li" {...{
          "href": "/configuration",
          "title": "configuration"
        }}>{`configuration`}</a>{` in the `}<a parentName="li" {...{
          "href": "/admin-interface",
          "title": "admin interface"
        }}>{`admin interface`}</a>{` or via the `}<a parentName="li" {...{
          "href": "/embed-code",
          "title": "embed code"
        }}>{`embed code`}</a>{``}</li>
    </ul>
    <p>{`See: `}<a parentName="p" {...{
        "href": "/Identity",
        "title": "Identity"
      }}>{`Identity`}</a>{``}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      